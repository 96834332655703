<template>
    <modal ref="modalCrearContacto" titulo="Crear contacto del proveedor" :cargado-modal="cargando" icon="entrega" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="crearContacto">
        <ValidationObserver ref="validacion">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col">
                    <div class="row mx-0">
                        <div class="col-6">
                            <p class="text-general f-14 pl-3">Nombre</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:40" name="nombre">
                                <el-input v-model="model.nombre" placeholder="Nombre del contacto" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <p class="text-general f-14 pl-3">Cargo</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:40" name="cargo">
                                <el-input v-model="model.cargo" placeholder="Cargo del contacto" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6 mt-4">
                            <p class="text-general f-14 pl-3">Número de teléfono</p>
                            <ValidationProvider v-slot="{errors}" rules="required|max:25" name="número de télefono">
                                <el-input v-model="model.telefono" placeholder="Número de télefono" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6 mt-4">
                            <p class="text-general f-14 pl-3">Correo eléctronico</p>
                            <ValidationProvider v-slot="{errors}" rules="max:60|email" name="correo eléctronico">
                                <el-input v-model="model.correo" placeholder="Correo eléctronico" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 mt-4">
                            <p class="f-14 text-general pl-3">
                                Observaciones
                            </p>
                            <ValidationProvider v-slot="{errors}" rules="max:500" name="observaciones">
                                <el-input v-model="model.observaciones" type="textarea" :rows="4" placeholder="Comentario del proveedor" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Proveedor from "~/services/proveedores/proveedores";
export default {
    data(){
        return {
            model: {
                nombre: null,
                cargo: null,
                telefono: null,
                correo: null,
                observaciones: null,
                id_proveedor: null
            },
            cargando: false
        }
    },
    methods: {
        toggle(){
            this.$refs.modalCrearContacto.toggle();
        },
        async crearContacto(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                this.model.id_proveedor = this.$route.params.id_proveedor

                this.cargando = true
                const { data } = await Proveedor.crearContacto(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.$refs.modalCrearContacto.toggle()
                this.$emit('agregar',data.model)
                this.limpiar()
            } catch (e){
                this.error_catch(e)
            }finally{
                this.cargando = false
            }
        },
        limpiar(){
            this.model = {
                nombre: null,
                cargo: null,
                telefono: null,
                correo: null,
                observaciones: null,
                id_proveedor: null
            }
        },
    }
}
</script>

<style>

</style>
